
































































































































































































































































































































































































































  .dialog ::v-deep .el-dialog {
   width: 650px !important;
}
.Userdialog::v-deep .el-dialog {
    width: 920px !important;
 }

    .v-distpicker ::v-deep select {
      height: 32px !important;
   
      padding-top: 0.4rem;
      font-size: 12px;
    }
  .mr15 {
    margin-right: 18px;
  }
  
  p {
    margin: 0;
    padding: 0;
  }
  
  ol,
  ul {
    margin: 0;
    padding: 0;
  }
  
  .content ul {
    list-style: none;
  
    li {
      display: flex;
      align-items: center;
      margin-top: 25px;
  
      span {
        font-weight: bold;
        display: block;
        width: 120px;
      }
  
      img {
        display: block;
  
        width: 100px;
      }
    }
  }
  
  
  .CheckInTimeTitle {
    font-size: 12px;
    color: #606266;
    margin-right: 5px;
  }
  .Price {
    display: flex;
    flex-wrap: wrap;
  }
  
  .resetInputSize {
    width: 100px;
  }
  
  .quill-editor {
    line-height: normal;
  }
  
  .goodDesc p img {
    width: 100px;
  }
  .el-buttonr {
    width: 70px;
    height: 25px;
  }
  .goods-details {
    .el-row {
      margin-bottom: 10px;
    }
  
    .goodDesc {
      p {
        img {
          width: 100px !important;
        }
      }
    }
  }
  
  .el-tag + .el-tag {
    margin-left: 10px;
 
  }
  
  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  
  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }
  .el-icon-s-fold,
  .el-icon-s-unfold {
    font-size: 35px;
    margin: 22px;
    color: #999;
  }
  